<template>
  <div class="space-y-4">
    <article v-if="canCreatePSMManualRecord || canCreateShiftEndSummary">
      <h3 class="subheader">Quick Links</h3>
      <br />
      <section
        class="relative z-0 rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          v-if="canCreatePSMManualRecord"
          variant="primary"
          outline
          rounded="none"
          @click="createManualTransaction"
          icon="duplicate"
          text="Create PSM Manual Record"
        >
        </asom-button>
        <asom-button
          v-if="canCreateShiftEndSummary"
          variant="primary"
          outline
          rounded="none"
          @click="createShiftEndSummary"
          icon="box"
          text="Create Shift End Summary"
        >
        </asom-button>
      </section>
    </article>

    <div v-if="!isLoading" class="space-y-4">
      <dl class="mt-5 gap-4 flex">
        <div
          v-for="(item, index) in psmBalanceModels" :key="index"
          class="px-4 py-5 w-72 bg-white shadow rounded-lg overflow-hidden sm:p-6 flex"
        >
          <div class="flex-shrink-0">
            <div
              class="m-2 mt-0 h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center"
            >
              <asom-icon icon="credit-card" />
            </div>
          </div>
          <div class="flex-1 min-w-0 pr-3">
            <dt class="text-sm font-medium text-gray-500 truncate">
              {{ item.stationPSMName }}
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-gray-900">
              {{ formatCurrency(item.currentBalance) }}
            </dd>
          </div>
        </div>
      </dl>
      <div class="space-y-4">
        <asom-card title="PSM Manual Transactions Summary">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
            <asom-form-field
              label="Date Created"
              required
              :state="inputStates('filters.dateRange')"
            >
              <asom-input-date-range
                v-model="filters.dateRange"
                datepicker-placement="bottom"
                :state="inputStates('filters.dateRange')"
              />
            </asom-form-field>
            <asom-form-field
              label="Transaction Number"
              required
              :state="inputStates('filters.transactionNumber')"
            >
              <asom-input-text
                v-model="filters.transactionNumber"
                :state="inputStates('filters.transactionNumber')"
              />
            </asom-form-field>
          </div>
          <template #footer>
            <asom-button
              text="Reset Filters"
              variant="secondary"
              @click="resetFilters(filters)"
            />
            <asom-button text="Apply" @click="getPageData" />
          </template>
        </asom-card>
        <asom-card title="PSM Manual Transactions Summary">
          <asom-client-table
            :columns="[
              'transactionNo',
              'createdBy',
              'psm',
              'inputType',
              'input',
              'dateCreated',
              'amount',
              'action',
            ]"
            :data="getTableData"
            :sortableColumns="[
              'transactionNo',
              'createdBy',
              'psm',
              'inputType',
              'input',
              'dateCreated',
              'amount',
            ]"
              :searchableDateColumns="['dateCreated']"
              :searchableCurrencyColumns="['amount']"
          >
            <template v-slot:header_transactionNo>Transaction No</template>
            <template v-slot:dateCreated="scopedSlots">
              <span>{{ displayUtcDate(scopedSlots.data) }}</span>
            </template>
            <template v-slot:psm="scopedSlots">
              <span>{{ get(scopedSlots.data, "name") }}</span>
            </template>
            <template v-slot:header_amount>Total</template>
            <template v-slot:amount="scopedSlots">{{
              formatCurrency(scopedSlots.data)
            }}</template>
            <template v-slot:transactionNo="scopedSlots">
              <asom-link
                v-if="!scopedSlots.rowData.isDeleted"
                @click="navigateToView(scopedSlots.rowData)"
                >{{ scopedSlots.data }}
              </asom-link>
              <span v-else class="line-through">
                {{ scopedSlots.data }}
              </span>
            </template>
            <template v-slot:action="scopedSlots">
              <div
                class="flex flex-col gap-1 w-48"
                v-if="
                  isTransactionEditable(scopedSlots.rowData) &&
                  canCreatePSMManualRecord
                "
              >
                <asom-button
                  size="sm"
                  outline
                  text="Cancel Entry"
                  variant="error"
                  @click="cancelClicked(scopedSlots.rowData)"
                />
                <asom-button
                  size="sm"
                  outline
                  text="Update"
                  @click="navigateToUpdate(scopedSlots.rowData)"
                />
              </div>
            </template>
          </asom-client-table>
        </asom-card>
        <activity-log-list
          :data="activityLogs"
          title="PSM Manual Record Transaction Activity Log"
        />
      </div>

      <!-- 新增 --------------------------- -->
      <div class="space-y-4">
        <asom-card title="PSM Shift End Summary Transactions">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
            <asom-form-field
              label="Date Created"
              required
              :state="inputStates('endFilters.dateRange')"
            >
              <asom-input-date-range
                v-model="endFilters.dateRange"
                datepicker-placement="bottom"
                :state="inputStates('endFilters.dateRange')"
              />
            </asom-form-field>
            <asom-form-field
              label="Transaction Number"
              required
              :state="inputStates('endFilters.transactionNumber')"
            >
              <asom-input-text
                v-model="endFilters.transactionNumber"
                :state="inputStates('endFilters.transactionNumber')"
              />
            </asom-form-field>
          </div>
          <template #footer>
            <asom-button
              text="Reset Filters"
              variant="secondary"
              @click="resetFilters(endFilters)"
            />
            <asom-button text="Apply" @click="getTransactions" />
          </template>
        </asom-card>
        <asom-card title="PSM Shift End Summary Transactions">
          <asom-client-table
            :columns="[
              'referenceNo',
              'createdBy',
              'dateCreated',
              'shiftEndSummary_PSMCurrentShiftAmount_PSM01',
              'shiftEndSummary_PSMCurrentShiftAmount_PSM02',
              'shiftEndSummary_PSMCurrentShiftAmount_PSM03',
            ]"
            :data="engTableData"
            :sortableColumns="[
              'referenceNo',
              'createdBy',
              'dateCreated',
              'shiftEndSummary_PSMCurrentShiftAmount_PSM01',
              'shiftEndSummary_PSMCurrentShiftAmount_PSM02',
              'shiftEndSummary_PSMCurrentShiftAmount_PSM03',
            ]"
              :searchableDateColumns="['dateCreated']"
              :searchableCurrencyColumns="['amount']"
          >
            <template v-slot:header_referenceNo>Transaction No</template>
            <template v-slot:header_dateCreated>DATE CREATED</template>
            <template v-slot:header_shiftEndSummary_PSMCurrentShiftAmount_PSM01>TOTAL CASH IN PSM 1</template>
            <template v-slot:header_shiftEndSummary_PSMCurrentShiftAmount_PSM02>TOTAL CASH IN PSM 2</template>
            <template v-slot:header_shiftEndSummary_PSMCurrentShiftAmount_PSM03>TOTAL CASH IN PSM 3</template>

            <template v-slot:referenceNo="scopedSlots">
              <asom-link
                @click="endNavigateToView(scopedSlots.rowData)"
                >{{ scopedSlots.data }}
              </asom-link>
            </template>
            <template v-slot:dateCreated="scopedSlots">
              <span>{{ displayUtcDate(scopedSlots.data) }}</span>
            </template>
            <template v-slot:shiftEndSummary_PSMCurrentShiftAmount_PSM01="scopedSlots">{{
              formatCurrency(scopedSlots.data)
            }}</template>
            <template v-slot:shiftEndSummary_PSMCurrentShiftAmount_PSM02="scopedSlots">{{
              formatCurrency(scopedSlots.data)
            }}</template>
            <template v-slot:shiftEndSummary_PSMCurrentShiftAmount_PSM03="scopedSlots">{{
              formatCurrency(scopedSlots.data)
            }}</template>


          </asom-client-table>
        </asom-card>
        <activity-log-list
          :data="endActivityLogs"
          title="PSM Manual Record Transaction Activity Log"
        />
      </div>

    </div>
    <asom-modal
      title="Cancel Entry Confirmation"
      v-model="showCancelModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmitCancel"
            :disabled="isSubmittingCancel"
            :loading="isSubmittingCancel"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="toggleCancelModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { mapGetters } from "vuex";
import get from "lodash.get";
import { toMoment, displayUtcDate, displayUtcDateTime } from "@/helpers/dateTimeHelpers";
import {
  editPsmLog,
  shiftEndList,
  getPSMMainPageData,
} from "../../../../services/cashManagement.service";
import ActivityLogList from "../../_ActivityLogList.vue";
import { formatCurrency } from "../../../../helpers/numbers";

export default {
  components: { ActivityLogList },
  name: "ManualRecordMainPage",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().subtract(3, "months").toDate(),
          end: moment().add(1, "months").toDate(),
        },
        transactionNumber: null,
      },
      psmBalanceModels: [],
      isLoading: true,
      activityLogs: [],
      getTableData: [],
      showCancelModal: false,
      isSubmittingCancel: false,
      selectedTransaction: null,
      endFilters: {
        dateRange: {
          start: moment().subtract(3, "months").toDate(),
          end: moment().add(1, "months").toDate(),
        },
        transactionNumber: null,
      },
      engTableData: [],
      endActivityLogs: [],
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      canCreatePSMManualRecord: "auth/canCreatePSMManualRecord",
      canCreateShiftEndSummary: "auth/canCreateShiftEndSummary",
    }),
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(
          this.filters.dateRange.start,
          true
        );
        params["searchDateTo"] = this.parseDateTimeUTC(
          this.filters.dateRange.end,
          false
        );
      }
      params["search"] = this.filters.transactionNumber;
      return params;
    },
    queryTime() {
      let params = {};
      if (
        this.endFilters.dateRange &&
        this.endFilters.dateRange.start &&
        this.endFilters.dateRange.end
      ) {
        params["SearchDateFrom"] = this.parseDateTimeUTC(
          this.endFilters.dateRange.start,
          true
        );
        params["SearchDateTo"] = this.parseDateTimeUTC(
          this.endFilters.dateRange.end,
          false
        );
      }
      params["search"] = this.endFilters.transactionNumber;
      return params;
    },
  },
  mounted() {
    this.getPageData();
    this.getTransactions();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getPageData();
    },
  },
  methods: {
    get,
    displayUtcDateTime,
    formatCurrency,
    resetFilters(filters) {
      filters.dateRange = null;
      filters.transactionNumber = null;
    },
    isTransactionEditable(row) {
      return !row.isDeleted && !row.addedToShiftEnd;
    },
    displayUtcDate,
    createManualTransaction() {
      this.$router.push({ name: "Create Manual Record" });
    },
    createShiftEndSummary() {
      this.$router.push({
        name: "PSM Shift End Summary",
      });
    },
    navigateToView(selectedTransaction) {
      this.$router.push({
        name: "View Manual Record",
        params: selectedTransaction,
      });
    },
    endNavigateToView(params) {
      this.$router.push({
        name: "Summary Transactions Detial",
        query: {
          id: params.cashManagementFormId
        }
      });
    },
    async getPageData() {
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const resp = await getPSMMainPageData({
        pSCId: this.pscId,
        ...this.queryParams,
      });
      if (resp.success) {
        const data = resp.payload.data;
        const types = ["Passenger name","CAN ID","Notebox No.","Coinbox No.","PSM Top-up","Others"]
        this.getTableData = data.transactions.map(l => {
          return { ...l, inputType: types[l.inputType - 1] || '' }
        });
        const psmBalance = data.psmBalanceModels || [];
        this.psmBalanceModels.length = 0;
        psmBalance.forEach(item => {
          this.psmBalanceModels.push({
            ...item,
            currentBalance: get(item, "currentBalance", 0)
          })
        })

        let _activityLogs = get(data, "activityLogs", []);
        const fields = ['psm', 'inputType', 'gtm', 'input', 'description', 'waived', 'payment', 'receipt'];

        _activityLogs.forEach( log => {
          let result = [];
          var obj = get(log, "fieldChanges", {});
          if(obj){
            fields.forEach( field => {
              if(obj[field] || obj[field + 'Updated']){
                let temp =
                { fieldName: field,
                  values: {
                    oldValue: obj[field],
                    newValue: obj[field + 'Updated']
                  }
                }
                result.push(temp);
              }
            })
          }
          log.fieldChanges = result;
        })
        this.activityLogs = _activityLogs;
        this.isLoading = false;
      } else return false;
    },
    // 查询下方列表
    async getTransactions() {
      // await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const resp = await shiftEndList({
        AccountId: this.pscId,
        ...this.queryTime,
      });

      // console.log('请求下方列表-----',resp);
      if (resp.success) {
        const data = resp.payload;
        this.engTableData = data.list;

        this.endActivityLogs = data.list.map(r => {
          return {
            message: `Transaction ${r.referenceNo} was created`,
            officerName: r.createdBy,
            dateModified:  r.dateCreated,
          }
        })
      } else return false;
    },
    navigateToUpdate({ transactionId }) {
      this.$router.push({
        name: "Edit Manual Record",
        params: {
          transactionId,
        },
      });
    },
    cancelClicked(transaction) {
      this.selectedTransaction = transaction;
      this.toggleCancelModal(true);
    },
    toggleCancelModal(value) {
      this.showCancelModal = value;
    },
    async onSubmitCancel() {
      if (this.selectedTransaction.inputType == "Passenger name") {
          this.selectedTransaction.inputType = 1;
        } else if (this.selectedTransaction.inputType == "CAN ID") {
          this.selectedTransaction.inputType = 2;
        } else if (this.selectedTransaction.inputType == "Notebox No.") {
          this.selectedTransaction.inputType = 3;
        } else if (this.selectedTransaction.inputType == "Coinbox No.") {
          this.selectedTransaction.inputType = 4;
        } else if (this.selectedTransaction.inputType == "PSM Top-up") {
          this.selectedTransaction.inputType = 5;
        } else if (this.selectedTransaction.inputType == "Others") {
          this.selectedTransaction.inputType = 6;
        } else {
          this.selectedTransaction.inputType = 0;
        }
      this.isSubmittingCancel = true;
      const resp = await editPsmLog({
        id: get(this.selectedTransaction, "transactionId", ""),
        transactionNo: get(this.selectedTransaction, "transactionNo"),
        input: get(this.selectedTransaction, "input"),
        inputType: get(this.selectedTransaction, "inputType"),
        remarks: "void transaction",
        isVoided: true,
      });
      if (resp.success) {
        this.selectedTransaction = null;
        this.getPageData();
      } else {
        this.showError = true;
        this.error = resp.payload;
      }
      this.isSubmittingCancel = false;
      this.toggleCancelModal(false);
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf("day") : d.endOf("day");
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss");
      }
      return "";
    },
  },
};
</script>
